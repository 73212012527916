import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import React from "react";
import { MainLayout } from "../../../components/layout";
import { Link } from "react-router-dom";

export const CreateAccount = () => {
  return (
    <>
      <div className="mx-auto bg-baseColor h-screen flex flex-col justify-between ">
        <header>
          <MainLayout page={"founders"} />
        </header>
        <main className="mb-auto container px-4 mx-auto flex items-center justify-end h-full">
          <div className="w-5/12 bg-white px-5 py-8">
            <div className="font-bold text-2xl mb-3 flex items-center justify-between">
              <h1>Create a Founder's Account</h1>
              <span className="cursor-pointer">
                <ClearRoundedIcon />
              </span>
            </div>
            <form className="bg-white">
              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-1"
                  htmlFor="username"
                >
                  Founder's Name
                </label>
                <input
                  className="bg-gray-100 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                />
              </div>

              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-1"
                  htmlFor="email"
                >
                  Company Email Address
                </label>
                <input
                  className="bg-gray-100 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                />
              </div>

              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-1"
                  htmlFor="phone"
                >
                  Phone Number
                </label>
                <input
                  className="bg-gray-100 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="phone"
                  type="phone"
                />
              </div>

              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-1"
                  htmlFor="companyName"
                >
                  Company Name
                </label>
                <input
                  className="bg-gray-100 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="companyName"
                  type="text"
                />
              </div>

              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-1"
                  htmlFor="title"
                >
                  Professional Title
                </label>
                <input
                  className="bg-gray-100 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="title"
                  type="text"
                />
              </div>
              <div className="flex justify-center">
                <Link to="/founder/login">
                  <button
                    className="px-16 py-2 bg-black text-white text-lg font-bold rounded-sm"
                    type="submit"
                  >
                    Create Account
                  </button>
                </Link>
              </div>
            </form>
          </div>
        </main>
        <footer>
          <div className="container px-4 mx-auto h-full flex items-center justify-center "></div>
        </footer>
      </div>
    </>
  );
};
