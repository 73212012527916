import React from "react";
import { MainLayout } from "../../../components/layout";
import { Link } from "react-router-dom";

export const Passcode = () => {
  return (
    <>
      <div className="mx-auto bg-baseColor h-screen w-screen flex flex-col justify-between ">
        <header>
          <MainLayout page={"investors"} />
        </header>
        <main className="mb-auto container px-4 mx-auto flex items-center justify-center h-full">
          <div className="w-96">
            <div>
              <h1 className="text-xl font-extrabold">Passcode</h1>
              <p className="mt-2">Company Email Address</p>
            </div>
            <div className="flex flex-col gap-5 mt-3">
              <input
                type="text"
                className="py-3 px-2 outline-none rounded-md"
              />
              <Link to="/investor/login-passcode">
                <button className="py-2 px-4 w-full rounded-md text-lg bg-black text-white font-bold">
                  Continue
                </button>
              </Link>
            </div>
          </div>
        </main>
        <footer>
          <div className="container px-4 mx-auto h-full flex items-center justify-center "></div>
        </footer>
      </div>
    </>
  );
};
