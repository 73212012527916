import React from 'react';
import { MainLayout } from '../../../components/layout';

export function Home()  {
  return (
    <>
      <MainLayout page="founders" />
      <div>Founders Uganda Home Page</div>
    </>
  );
}


