import React from "react";
import { MainLayout } from "../../../components/layout";
import building from "../../../assets/image 1.png";
import building1 from "../../../assets/image 2.png";
import building2 from "../../../assets/image 3.png";
import { Link } from "react-router-dom";

export const SignUp = () => {
  return (
    <>
      <div className="mx-auto bg-baseColor h-screen flex flex-col justify-between ">
        <header>
          <MainLayout page={"investors"} />
        </header>
        <main className="mb-auto container px-4 mx-auto flex items-center justify-center h-full">
          <div className="">
            <Link to="/investor/create-account">
              <button className="py-3 px-4 rounded-md text-lg bg-white font-bold">
                Become An Investor
              </button>
            </Link>
          </div>
        </main>
        <footer>
          <div className=" px-4 mx-auto h-full flex items-center justify-center overflow-hidden">
            <img src={building} className="w-full h-full" alt="building" />
            <img src={building1} className="w-full h-full" alt="building" />
            <img src={building2} className="w-full h-full" alt="building" />
          </div>
        </footer>
      </div>
    </>
  );
};
