import {
  SignUp,
  CreateAccount,
  Login,
  Passcode,
  Home as FoundersHome,
} from "../features/founders/routes";


import {
  SignUp as InvestorSignUp,
  CreateAccount as InvestorCreateAccount,
  Login as InvestorLogin,
  Passcode as InvestorPasscode,
} from "../features/investors";

export const publicRoutes = [
  { path: "/founder", element: <SignUp /> },
  { path: "/investor", element: <InvestorSignUp /> },
  { path: "/founder/login", element: <Login /> },
  { path: "/founder/login-passcode", element: <Passcode /> },
  { path: "/founder/create-account", element: <CreateAccount /> },
  { path: "/founder/home", element: <FoundersHome /> },
  { path: "/investor/create-account", element: <InvestorCreateAccount /> },
  { path: "/investor/login", element: <InvestorLogin /> },
  { path: "/investor/login-passcode", element: <InvestorPasscode /> },
];
